<template>
	<div style="width: 1186px;margin: 50px auto;">
			<div class="cnt_top">
			  <div class="top_title">
				<h1 v-if="types==1">院校发布</h1>
				<h1 v-if="types==2">企业发布</h1>
			  </div>
			  <div class="top_path">
				<h1>当前位置：
				  <router-link to="/home"> 首页</router-link>
				   >> 
				  <router-link to="/hzfabu" v-if="types==1">院校发布</router-link>
				  <router-link to="/hzfabu" v-if="types==2">企业发布</router-link>
				</h1>
			  </div>
			</div>
			<div style="margin-top: 30px;">
				<el-form ref="form" :model="form" label-width="40px">
					<div style="display: flex;">
					<div style="width: 100px;height: 30px;background-color: #8C939D;text-align: center;line-height: 30px;">
					项目标题：
					</div>
					  <el-form-item>
						<el-input v-model="form.projectTitle" style="width: 470px;"></el-input>
					  </el-form-item>
				  </div>
					<div style="display: flex;">
					<div style="width: 100px;height: 30px;background-color: #8C939D;text-align: center;line-height: 30px;">
					项目单位：
					</div>
					<el-form-item>
					  <el-input v-model="form.projectDepart" style="width: 470px;"></el-input>
					</el-form-item>
					</div>
					<div style="display: flex;margin-top: 20px;">
						<div style="width: 100px;height: 30px;background-color: #8C939D;text-align: center;line-height: 30px;">
						项目内容：
						</div>
						<div style="border: 1px solid #ccc; width: 1100px;margin: 0 auto;margin-left: 40px;">
								<Toolbar
									style="border-bottom: 1px solid #ccc"
									:editor="editor"
									:defaultConfig="toolbarConfig"
									:mode="mode"
								/>
								<Editor
									style="height: 500px; overflow-y: hidden;"
									v-model="form.projectContent"
									:defaultConfig="editorConfig"
									:mode="mode"
									@onCreated="onCreated"
								/>
						</div>	
					</div>
				  <el-form-item>
					<el-button type="primary" @click="onSubmit" style="width: 200px;margin-top: 20px;margin-left: 450px;">提交</el-button>
				  </el-form-item>
				</el-form>
			</div>		
</div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {addProject} from '../../api/index'
export default {
	components: { Editor, Toolbar },
	data() {
	  return {
		  //用户类型
		  types:0,
		editor: null,
		html: '',
		toolbarConfig: { },
		imageUrl:'',
		editorConfig: { 
			placeholder: '请输入内容...', 
			MENU_CONF: {
			// 配置上传图片
			  uploadImage: {
				server: "http://39.108.229.129:82/common/upload2",
				fieldName: 'file',
			  },
			  
			},
			
			},
		mode: 'default', // or 'simple'
		 form: {
				'projectTitle':'',
				'projectDepart':'',
				'projectType':'',
				'projectContent':'',
				'companyId':'',
				'schoolId':''
		        }
	  };
	},
	 methods: {
	        onCreated(editor) {
	            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
	        },
			
			onSubmit(){
				// this.form.schoolId=sessionStorage.getItem("id");
				let type=sessionStorage.getItem("userType");
				if(type==1){
					this.form.projectType="院校项目"
					this.form.schoolId=sessionStorage.getItem("id");
					let params={...this.form}
					addProject(params).then(res=>{
						console.log(res)
						if(res.code==1){
							alert("提交成功")
							this.$router.push("/cooperation")
						}
							
					})
				}
				if(type==2){
					this.form.projectType="企业项目"
					this.form.companyId=sessionStorage.getItem("id");
					let params={...this.form}
					addProject(params).then(res=>{
						console.log(res)
						if(res.code==1){
							alert("提交成功")
							this.$router.push("/cooperation")
						}
					})
				}	
			},
			//文件
			handlePreview(file){
				this.form.filePath=file.response.data
				console.log(file)
				window.open(file.response.data)
			},
			beforeDestroy() {
			       const editor = this.editor
			       if (editor == null) return
							
			       editor.destroy() // 组件销毁时，及时销毁编辑器
			   },
			//图片回显
			handleAvatarSuccess(res, file) {
			  this.imageUrl = URL.createObjectURL(file.raw);
			  this.form.picPath= res.data
					console.log(res)
			},
			beforeAvatarUpload(file) {
			    const isJPG = file.type === 'image/jpeg';
			    const isLt2M = file.size / 1024 / 1024 < 2;
			
			    if (!isJPG) {
			      this.$message.error('上传图片只能是 JPG 格式!');
			    }
			    if (!isLt2M) {
			      this.$message.error('上传头像图片大小不能超过 2MB!');
			    }
			    return isJPG && isLt2M;
			  }
			
	    },
		created() {
			this.types=sessionStorage.getItem("userType");
		}
		
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.wrapper {
		padding-top: 40px;
	    MARGIN: 0px auto;
	    WIDTH: 1186px;
	    height: auto;
	    background-color: #FFFFFF;
	    overflow: hidden;
	}
	.cnt {
	    width: 1188px;
	    height: auto;
	    background-color: #ffffff;
	    margin: 50px 0 0 0;
	    overflow: hidden;
	}
	.cnt_top {
	    border-bottom: #CCCCCC 1px solid;
	    width: 1186px;
	    height: 49px;
	    overflow: hidden;
	}
	.top_title {
	    color: #010204;
	    font-size: 22px;
	    float: left;
	    width: 50%;
	    height: 50px;
	    overflow: hidden;
	}
	.top_title h1 {
	    color: #373333;
	    float: left;
	    width: 100%;
	    line-height: 20px;
	    text-align: left;
	    text-indent: 5px;
	    font-size: 22px;
	    font-weight: 800;
	}
	.top_path {
	    display: block;
	    color: #968E8E;
	    font-size: 12px;
	    height: 50px;
	    line-height: 66px;
	    width: auto;
	    padding-right: 5px;
	    overflow: hidden;
	}
	.top_path h1 {
	    color: #968E8E;
	    height: 50px;
	    line-height: 20px;
	    text-align: right;
	    font-size: 18px;
	    font-weight: 500;
	}
	.top_path h1 a:visited {
	    color: #968E8E;
	    text-decoration: none;
	    font-size: 12px;
	    font-weight: 500;
	}
	
/* a:hover{
	
} */
.tab{
	width: 1186px;
	margin: 0 auto;
	margin-top: 20px;
}
.tab-head{
	width: 1186px;
	margin: 0 auto;
	border-bottom: 1px solid #ccc;
	overflow: hidden;
}
.tab-head ul li{
	text-align: center;
	width: 200px;
}
.tab-head ul li:hover{
	color: #409EFF;
	text-align: center;
	width: 200px;
}
</style>