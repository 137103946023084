<template>
	<div class="wrapper">
	  <div class="cnt_top">
		  <div class="top_title">
			<h1 v-if="type==1">通知文件</h1>
			<h1 v-if="type==2">政策解读</h1>
			<h1 v-if="type==3">企业法规</h1>
			<h1 v-if="type==4">教育法规</h1>
		  </div>
		  <div class="top_path">
			<h1>当前位置：
			  <router-link to="/home"> 首页</router-link>
			   >> 
			  <router-link to="/more" v-if="type==1">通知文件</router-link>
			  <router-link to="/more" v-if="type==2">政策解读</router-link>
			  <router-link to="/more" v-if="type==3">企业法规</router-link>
			  <router-link to="/more" v-if="type==4">教育法规</router-link>
			</h1>
		  </div>
	  </div>
	  <div class="tab-bottom">
	  		  <ul style="list-style-type:none;">
	  			  <div v-for="item in moreList" :key="item.projectId">
	  				<li style="margin-top: 30px;">
	  					<div style="display: flex;">
	  						<div style="width: 120px;height: 100px;text-align: center;line-height: 100px;background: #577BCF;font-size: 20px;color: white;">{{item.inforTime}}</div>
	  						<div @click="goDetail(item.inforId)" style="width: 1066px;height: 100px;background: #ccc;font-size: 20px;font-weight: 700; color:#595959;text-align: center;line-height: 100px;">{{item.inforTitle}}</div>
	  					</div>
	  				</li>
	  			  </div>
	  		  </ul>
	  </div>
	  <div class="block"  style="margin: 0 auto;width: 400px;">
	      <el-pagination
	        @size-change="handleSizeChange"
	        @current-change="handleCurrentChange"
	        :current-page.sync="currentPage"
	        :page-size="this.pageSize"
	        layout="prev, pager, next, jumper"
	  			:total="this.total"
	  			>
	      </el-pagination>
	    </div>
	</div>
</template>

<script>
import {getMorePage} from '../../api/index';
	export default{
		data(){
			return{
				type:'',
				currentPage: 1,
				pageSize:5,
				total:0,
				moreList:[]
			}
		},
		created() {
			this.type=this.$route.query.type
			console.log(this.type)
			getMorePage(this.type,this.currentPage,this.pageSize).then(res=>{
				// console.log(res)
				this.moreList=res.data.records
				this.total=res.data.total
			})
		},
		methods:{
			goDetail(id){
				 this.$router.push("/moredetail?id="+id+"&type="+this.type)
			},
			//分页
			 handleSizeChange(val) {
			        console.log(`每页 ${val} 条`);
				},
			  handleCurrentChange(val) {
				  this.currentPage=val
				console.log(`当前页: ${val}`);
				getMorePage(this.type,this.currentPage,this.pageSize).then(res=>{
					// console.log(res)
					this.moreList=res.data.records
					this.total=res.data.total
				})
				
			  }
		}
	}
</script>

<style>
	.wrapper {
		padding-top: 40px;
	    MARGIN: 0px auto;
	    WIDTH: 1186px;
	    height: auto;
	    background-color: #FFFFFF;
	    overflow: hidden;
	}
	.cnt {
	    width: 1188px;
	    height: auto;
	    background-color: #ffffff;
	    margin: 50px 0 0 0;
	    overflow: hidden;
	}
	.cnt_top {
	    border-bottom: #CCCCCC 1px solid;
	    width: 1186px;
	    height: 49px;
	    overflow: hidden;
	}
	.top_title {
	    color: #010204;
	    font-size: 22px;
	    float: left;
	    width: 50%;
	    height: 50px;
	    overflow: hidden;
	}
	.top_title h1 {
	    color: #373333;
	    float: left;
	    width: 100%;
	    line-height: 20px;
	    text-align: left;
	    text-indent: 5px;
	    font-size: 22px;
	    font-weight: 800;
	}
	.top_path {
	    display: block;
	    color: #968E8E;
	    font-size: 12px;
	    height: 50px;
	    line-height: 66px;
	    width: auto;
	    padding-right: 5px;
	    overflow: hidden;
	}
	.top_path h1 {
	    color: #968E8E;
	    height: 50px;
	    line-height: 20px;
	    text-align: right;
	    font-size: 18px;
	    font-weight: 500;
	}
	.top_path h1 a:visited {
	    color: #968E8E;
	    text-decoration: none;
	    font-size: 12px;
	    font-weight: 500;
	}
</style>
