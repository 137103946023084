<template>
	<div style="width: 1186px;margin: 0 auto;">
  
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="项目标题">
      <el-input v-model="form.projectTitle"></el-input>
    </el-form-item>
	<el-form-item label="单位名称">
	  <el-input v-model="form.projectDepart"></el-input>
	</el-form-item>
	<el-form-item label="项目类型">
	  <el-input v-model="form.projectType"></el-input>
	</el-form-item>
	<el-upload
	  class="upload-demo"
	  action="http://39.108.229.129:8888/common/upload3"
	  :on-preview="handlePreview"
	  :limit="10"
		>
	  <el-button size="small" type="primary">点击上传</el-button>
	</el-upload>
	项目内容：
	<div style="border: 1px solid #ccc; width: 1186px;margin: 0 auto;">
	        <Toolbar
	            style="border-bottom: 1px solid #ccc"
	            :editor="editor"
	            :defaultConfig="toolbarConfig"
	            :mode="mode"
	        />
	        <Editor
	            style="height: 500px; overflow-y: hidden;"
	            v-model="form.projectContent"
	            :defaultConfig="editorConfig"
	            :mode="mode"
	            @onCreated="onCreated"
	        />
	    </div>
		  <!-- {{html}} -->
	

		
    <el-form-item>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {addProject} from '../../api/index'
export default {
	components: { Editor, Toolbar },
	data() {
	  return {
		editor: null,
		html: '',
		toolbarConfig: { },
		editorConfig: { 
			placeholder: '请输入内容...', 
			MENU_CONF: {
			// 配置上传图片
			  uploadImage: {
				server: "http://39.108.229.129:8888/common/upload2",
				fieldName: 'file',
				
			},
			}
			},
		mode: 'default', // or 'simple'
		 form: {
		        'projectTitle':'',
				'projectDepart':'',
				'projectType':'',
				'projectContent':''
		        }
	  };
	},
	 methods: {
	        onCreated(editor) {
	            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
					
	        },
			onSubmit(){
				console.log(this.form)
				// let params = {...this.form}
				// addProject(params)
			},
			handlePreview(file){
				console.log(file)
				window.open(file.response.data)
			}
	    },
		 beforeDestroy() {
		        const editor = this.editor
		        if (editor == null) return
				
		        editor.destroy() // 组件销毁时，及时销毁编辑器
		    },
			
		
	
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	
/* a:hover{
	
} */
.tab{
	width: 1186px;
	margin: 0 auto;
	margin-top: 20px;
}
.tab-head{
	width: 1186px;
	margin: 0 auto;
	border-bottom: 1px solid #ccc;
	overflow: hidden;
}
.tab-head ul li{
	text-align: center;
	width: 200px;
}
.tab-head ul li:hover{
	color: #409EFF;
	text-align: center;
	width: 200px;
}
</style>