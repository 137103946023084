//配置路由
import Vue from 'vue';
import VueRouter from 'vue-router';
//使用路由
Vue.use(VueRouter);

//引入路由组件
import Home from '@/pages/Home';
import Advisory from '@/pages/Advisory';
import More from '@/pages/Advisory/more';
import MoreDetail from '@/pages/Advisory/moredetail';
import School from '@/pages/School';
import Company from '@/pages/Company';
import SchoolDetail from '@/pages/SchoolDetail';
import Upload from '@/pages/Upload';
import Coop from '@/pages/Coop';
import CompanyDetail from '@/pages/CompanyDetail';
import InformationDetail from '@/pages/InformationDetail';
import Recruit from '@/pages/Recruit';
import Fabu from '@/pages/Recruit/fabu';
import RecruitDetail from '@/pages/RecruitDetail';
import Cooperation from '@/pages/Cooperation';
import Hzfabu from '@/pages/Cooperation/hzfabu';
import ProjectDetail from '@/pages/ProjectDetail';
import Login from '@/pages/Login'
import Register from '@/pages/Login/register'
import User from '@/pages/Login/user'

//配置路由
export default new VueRouter({
    routes:[
		 {
		    path: "/",
		    redirect: "/home",
		},
        {
            path:"/home",
            component:Home,
			meta:{show:true}
        },
        {
            path:"/advisory",
            component:Advisory,
			meta:{show:true}
        },
		{
			path:"/more",
			component:More,
			meta:{show:true}	
		},
		{
			path:"/moredetail",
			component:MoreDetail,
			meta:{show:true}	
		},
        {
            path:"/school",
            component:School,
			meta:{show:true}
        },
        {
            path:"/company",
            component:Company,
			meta:{show:true}
        },
        {
            path:"/schooldetail",
            component:SchoolDetail,
			meta:{show:true}
        },
		{
			path:"/upload",
			component:Upload,
			meta:{show:true}
		},
		{
			path:"/coop",
			component:Coop,
			meta:{show:true}
		},
		{
			path:"/companydetail",
			component:CompanyDetail,
			meta:{show:true}
		},
		{
		    path:"/informationdetail",
		    component:InformationDetail,
			meta:{show:true}
		},
		{
			path:"/cooperation",
			component:Cooperation,
			meta:{show:true}
		},
		{
			path:"/hzfabu",
			component:Hzfabu,
			meta:{show:true}
		},
		{
			path:"/projectdetail",
			component:ProjectDetail,
			meta:{show:true}
		},
		{
		    path:"/recruit",
		    component:Recruit,
			meta:{show:true}
		},
		{
		    path:"/fabu",
		    component:Fabu,
			meta:{show:true}
		},
		{
		    path:"/recruitdetail",
		    component:RecruitDetail,
			meta:{show:true}
		},
		{
			path:"/login",
			component:Login,
			meta:{show:false}
		},
		{
			path:"/register",
			component:Register,
			meta:{show:true}
		},
		{
			path:"/user",
			component:User,
			meta:{show:true}
		}
    ]
})
