<template>
    <div class="wrapper">
      <div class="cnt">
        <div class="cnt_top">
            <div class="top_title">
              <h1>企业资源库</h1>
            </div>
            <div class="top_path">
              <h1>当前位置：
                <router-link to="/home"> 首页</router-link>
                 >> 
                <router-link to="/companydetail">企业资源库</router-link>
              </h1>
            </div>
        </div>

        <div class="itd">
          <div class="itd_title">
            <div class="title_h">
              <h1>{{company.companyName}}</h1>
            </div>
          </div>
        </div>
        <div class="center">
          <div v-if="company.companyPath==null">
				<img src="../../assets/nopicr.jpg" >
          </div>
          <div v-else>
          		<img :src="baseUrl+company.companyPath" >
          </div>
          <div class="right">
            <div class="tl">
              <strong>企业性质：</strong>{{company.companyCharacter}}
            </div>
            <div class="tl">
              <strong>规模人数：</strong>{{company.companyScale}}
            </div>
            <div class="tl">
              <strong>企业地址：</strong>{{company.companyAddress}}
            </div>
          </div>
          <!-- <div class="lx">
            <div class="lx_c">联系人：</div>
            <div class="lx_c">联系电话：</div>
            <div class="lx_c">邮箱：</div>
          </div> -->
        </div>

        <div class="items_top">
            <div class="title_items">
              <h1>企业简介</h1>
            </div>
        </div>
        <div style="width:1100px;margin: 0 auto">
          <h3 style="font-weight: 200;">{{company.companyIntro}}</h3>
        </div>
      </div>
  </div>
</template>

<script>
import {getCompanyOne} from '../../api/index'
export default {
  data(){
    return{
		baseUrl:"http://39.108.229.129:82",
      cid:'',
      company:{},
    }
  },
  created(){
    this.cid=this.$route.query.id
    // console.log(cid)
    this.companyOne()
  },
  methods:{
    companyOne(){
      getCompanyOne(this.cid).then(res=>{
        console.log(res)
        this.company=res.data
      })
    }
  },
 
}
</script>

<style scoped>
.wrapper {
    MARGIN: 0px auto;
    WIDTH: 1188px;
    height: auto;
    background-color: #FFFFFF;
    overflow: hidden;
}
.cnt {

    width: 1188px;
    height: auto;
    background-color: #ffffff;
    margin: 50px 0 0 0;
    overflow: hidden;
}
.cnt_top {
    border-bottom: #CCCCCC 1px solid;
    width: 1188px;
    height: 49px;
    overflow: hidden;
}
.top_title {
    color: #010204;
    font-size: 22px;
    float: left;
    width: 50%;
    height: 50px;
    overflow: hidden;
}
.top_title h1 {
    color: #373333;
    float: left;
    width: 100%;
    line-height: 20px;
    text-align: left;
    text-indent: 5px;
    font-size: 22px;
    font-weight: 800;
}
.top_path {
    display: block;
    color: #968E8E;
    font-size: 12px;
    height: 50px;
    line-height: 66px;
    width: auto;
    padding-right: 5px;
    overflow: hidden;
}
.top_path h1 {
    color: #968E8E;
    height: 50px;
    line-height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
}
.top_path h1 a:visited {
    color: #968E8E;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}

.itd {
    margin-top: 15px;
    width: 1188px;
    height: auto;
    overflow: hidden;
}
.itd_title {
    float: left;
    background: url(../../assets/itd_title_bg.jpg) no-repeat center top; width: 1185px;
    width: 1185px;
    height: 86px;
    overflow: hidden;
}
.title_h {
    float: left;
    width: 885px;
    height: 86px;
    overflow: hidden;
}
.title_h h1 {
    color: #ffffff;
    float: left;
    width: auto;
    line-height: 56px;
    text-align: left;
    text-indent: 40px;
    font-size: 26px;
    font-weight: 900;
    font-family: Microsoft Yahei;
}
.center {
  display: flex;
    margin-top: 25px;
    background-color: #EDEEF1;
    width: 1185px;
    height: auto;
    overflow: hidden;
}
.center img{
  margin:15px 0 15px 25px;
  width: 204px;
  height: 204px;
}
.right{
  margin-left: 200px;
  margin-top: 20px;
}
.tl {
    font-size: 20px;
    color: #2e3033;
    line-height: 48px;
    text-align: left;
    font-weight: 500;
    overflow: hidden;
}
.lx{
  margin-top: 138px;
  margin-left: 350px;
}

.items_top {
    margin-top: 25px;
    background-color: #EAEAEA;
    width: 1185px;
    height: 39px;
    overflow: hidden;
}
.title_items {
    float: left;
    background: url(../../assets/title_items.jpg) repeat-x left;
    width: auto;
    height: 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    overflow: hidden;
}
.title_items h1 {
    float: left;
    padding-left: 35px;
    padding-right: 35px;
    color: #ffffff;
    width: auto;
    line-height: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
</style>