<template>
  <div class="tab">
	  <div class="tab-head">
		  <ul style="display: flex;font-size: 25px;list-style-type:none;">
		  	<li @click="schoolProject">院校合作项目</li>丨
			<li @click="companyProject">企业合作项目</li>
		  </ul>
	  </div>
	  <div class="tab-bottom">
		  <ul style="list-style-type:none;">
			  <div v-for="item in projectList" :key="item.projectId">
				<li style="margin-top: 30px;">
					<div style="display: flex;">
						<div style="width: 120px;height: 100px;text-align: center;line-height: 100px;background: #577BCF;font-size: 20px;color: white;">{{item.projectTime}}</div>
						<div @click="goDetail(item.projectId)" style="width: 1066px;height: 100px;background: #ccc;font-size: 20px;font-weight: 700; color:#595959;text-align: center;line-height: 100px;">{{item.projectTitle}}</div>
					</div>
				</li>
			  </div>
		  </ul>
	  </div>
	  <div class="block"  style="margin: 0 auto;width: 400px;">
	      <el-pagination
	        @size-change="handleSizeChange"
	        @current-change="handleCurrentChange"
	        :current-page.sync="currentPage"
	        :page-size="this.pageSize"
	        layout="prev, pager, next, jumper"
			:total="this.total"
			>
	      </el-pagination>
	    </div>
  </div>
</template>

<script>
import {getProjectList} from '../../api/index'
export default {
	data() {
	  return {
		  type:"院校项目",
		  projectList:[],
		   currentPage: 1,
		   pageSize:5,
		   total:0
	  }
	},
	created() {
		this.init()
	},
	 methods: {
	     init(){
			 
			 getProjectList(this.type,this.currentPage,this.pageSize).then(res=>{
				 this.projectList=res.data.records
				 this.total=res.data.total
			 })
		 },
		schoolProject(){
			this.projectList=[]
			this.type="院校项目"
			getProjectList(this.type,this.currentPage,this.pageSize).then(res=>{
				 console.log(res)
				 this.projectList=res.data.records
				  this.total=res.data.total
			})
		},
		companyProject(){
			this.projectList=[]
			this.type="企业项目"
			getProjectList(this.type,this.currentPage,this.pageSize).then(res=>{
				 console.log(res)
				 this.projectList=res.data.records
				  this.total=res.data.total
			})
		},
		goDetail(id){
			 this.$router.push("/projectdetail?id="+id)
		},
		//分页
		 handleSizeChange(val) {
		        console.log(`每页 ${val} 条`);
			},
		  handleCurrentChange(val) {
			  this.currentPage=val
			console.log(`当前页: ${val}`);
			this.type="院校项目"
			getProjectList(this.type,this.currentPage,this.pageSize).then(res=>{
				 console.log(res)
				 this.projectList=res.data.records
				  this.total=res.data.total
			})
		  }
	}
			
		
	
}
</script>
<style scoped>
	
/* a:hover{
	
} */
.tab{
	width: 1186px;
	margin: 0 auto;
	margin-top: 20px;
}
.tab-head{
	width: 1186px;
	margin: 0 auto;
	border-bottom: 1px solid #ccc;
	overflow: hidden;
}
.tab-head ul li{
	text-align: center;
	width: 200px;
}
.tab-head ul li:hover{
	color: #409EFF;
	text-align: center;
	width: 200px;
}
</style>