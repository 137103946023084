<template>
	<div class="wrapper">
		  <div class="cnt_top">
		      <div class="top_title">
		        <h1 v-if="type==1">通知文件</h1>
				<h1 v-if="type==2">政策解读</h1>
				<h1 v-if="type==3">企业法规</h1>
				<h1 v-if="type==4">教育法规</h1>
		      </div>
		  </div>
		  <div class="cnt_center">
			  <h1 style="text-align: center;color:#9A6E3A">{{detailList.inforTitle}}</h1>
			  <div style="text-align: center;font-size: 14px;color:red">
				  [ 发布时间：{{detailList.inforTime}}]
			  </div>
		  </div>
		  <div class="cnt_bottom" style="width: 1100px;margin: 0 auto;margin-top: 20px;">
			<div v-html="detailList.inforContent"></div>
		  </div>
	</div>
</template>

<script>
import {getInforDetail} from '../../api/index'
export default{
	data(){
		return{
			mid:'',
			detailList:[],
			type:''
		}
	},
	created() {
		this.mid=this.$route.query.id
		this.type=this.$route.query.type
		console.log(this.type)
		this.moreDetail()
	},
	methods:{
		moreDetail(){
		  getInforDetail(this.mid).then(res=>{
		    console.log(res)
			this.detailList=res.data
		  })
		}
	}
}
</script>

<style scoped>
	.wrapper {
	    MARGIN: 0px auto;
	    WIDTH: 1188px;
	    height: auto;
	    background-color: #FFFFFF;
	    overflow: hidden;
		padding-top: 40px;
	}
	.cnt {
	
	    width: 1188px;
	    height: auto;
	    background-color: #ffffff;
	    margin: 50px 0 0 0;
	    overflow: hidden;
	}
	.cnt_top {
	    border-bottom: #CCCCCC 1px solid;
	    width: 1188px;
	    height: 49px;
	    overflow: hidden;
	}
	.top_title {
	    color: #010204;
	    font-size: 22px;
	    float: left;
	    width: 50%;
	    height: 50px;
	    overflow: hidden;
	}
	.top_title h1 {
	    color: #373333;
	    float: left;
	    width: 100%;
	    line-height: 20px;
	    text-align: left;
	    text-indent: 5px;
	    font-size: 22px;
	    font-weight: 800;
	}
	.top_path {
	    display: block;
	    color: #968E8E;
	    font-size: 12px;
	    height: 50px;
	    line-height: 66px;
	    width: auto;
	    padding-right: 5px;
	    overflow: hidden;
	}
	.top_path h1 {
	    color: #968E8E;
	    height: 50px;
	    line-height: 20px;
	    text-align: right;
	    font-size: 18px;
	    font-weight: 500;
	}
	.top_path h1 a:visited {
	    color: #968E8E;
	    text-decoration: none;
	    font-size: 12px;
	    font-weight: 500;
	}
</style>
