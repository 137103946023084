<!-- <template>
  <div>综合资讯</div>
</template>

<script>
export default {

}
</script>

<style>

</style> -->
<template>
<div class="wrapper">
  <div class="contents">
    <div class="news_top">
      <div class="news_title">
        <h1>行业动态</h1>
      </div>
      <div class="news_more">
        <a>更多>></a>
      </div>
    </div>
    <div class="news_mid">
        <div class="news_lb">
             <img src="@/assets/news_lb/lb1.jpg" alt="">
        </div>
        <div class="news_list" >
            <ul style="list-style-type:none;">
              <div v-for="item in dt" :key="item.id" style="display: flex;">
                <li>>
                 {{item.con}}
               </li>
               <div style="margin-left:40px">{{item.time}}</div>
              </div>
            </ul>
        </div>
    </div>
  </div>
  
  <!-- mt:20 -->
  <!-- <table width="100%" height="20" style="float:none;clear:both;"/> -->
  
  <div class="larger">
    <!-- 通知文件 -->
    <div>
      <div class="news_left">
        <div class="news_title">
            <h1>通知文件</h1>
        </div>
        <div class="news_more">
          <a @click="gotoMore(1)">更多>></a>
        </div>
      </div>
      <div class="larger_midk">
          <ul>
            <div v-for="(item,index) in informationList" v-if="item.inforType==1" :key="item.inforId" 
				style="float:left;width:460px;margin-left:23px;">
              <li v-if="index<8"  @click="toDetails(item.inforId)">{{item.inforTitle}}</li>
            </div>
          </ul>
      </div>
    </div>
    <!-- 政策解读 -->
    <div style="margin_left:15px">
      <div class="news_left">
        <div class="news_title">
            <h1>政策解读</h1>
        </div>
        <div class="news_more">
          <a @click="gotoMore(2)">更多>></a>
        </div>
      </div>
      <div class="larger_midk">
		<ul>
		  <div v-for="(item,index) in informationList" v-if="item.inforType==2" :key="index" 
			style="float:left;width:460px;margin-left:23px;">
		    <li v-if="index<9*2"  @click="toDetails(item.inforId)">{{item.inforTitle}}</li>
		  </div>
		</ul>
      </div>
    </div>
  </div>
  <div class="larger">
    <!-- 企业法规 -->
    <div>
      <div class="news_left">
        <div class="news_title">
            <h1>企业法规</h1>
        </div>
        <div class="news_more">
          <a @click="gotoMore(3)">更多>></a>
        </div>
      </div>
      <div class="larger_midk">
          <ul>
            <div v-for="(item,index) in informationList" v-if="item.inforType==3" :key="item.inforId" 
				style="float:left;width:460px;margin-left:23px;">
              <li v-if="index<14*2"  @click="toDetails(item.inforId)">{{item.inforTitle}}</li>
            </div>
          </ul>
      </div>
    </div>
    <!-- 教育法规 -->
    <div style="news_left:15px">
      <div class="news_left">
        <div class="news_title">
            <h1>教育法规</h1>
        </div>
        <div class="news_more">
          <a @click="gotoMore(4)">更多>></a>
        </div>
      </div>
      <div class="larger_midk">
		<ul>
		  <div v-for="(item,index) in informationList" v-if="item.inforType==4" :key="item.inforId" 
			style="float:left;width:460px;margin-left:23px;">
			<li v-if="index<19*2"  @click="toDetails(item.inforId)">{{item.inforTitle}}</li>
		  </div>
		</ul>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import {getSchoolList,getCompanyList,getInforList} from '../../api/index';
export default {
    data(){
      return{
        schoolList:[],
        companyList:[],
		informationList:[],
        images:[],
        dt:[
          {id:1,con:"新闻发布会 | 介绍教育系统学习宣传贯彻落实新修订《中华人民共和国职业教育法》有关情况",time:"2022-05-17"},
          {id:2,con:"快速优化新时代职教教师队伍结构",time:"2022-02-17"},
          {id:3,con:"2021年全国教育事业统计主要结果发布",time:"2022-04-17"},
          {id:4,con:"教育部：2022年职业教育重点工作",time:"2022-03-17"},
          {id:5,con:"教育部2022年工作要点",time:"2022-01-17"}
          ]
      }
    },
    created(){
      this.getSchool();
      this.getCompany();
	  this.getInfor();
    },
    methods:{
      //获得院校库
      getSchool(){
        getSchoolList().then(res=>{
          console.log(res)
          this.schoolList=res.data
        })
      },
      //获得企业库
      getCompany(){
        getCompanyList().then(res=>{
          console.log(res)
          this.companyList=res.data
        })
      },
      //院校详情
      toDetails(id){
        this.$router.push("/informationdetail?id="+id)
      },
	  //获得资讯库
	  getInfor(){
		  getInforList().then(res=>{
			  console.log(res)
			  this.informationList = res.data
		  })
	  },
	  gotoMore(type){
		  this.$router.push("/more?type="+type)
	  }
    },
    mounted(){
         
    }
}
</script>

<style scoped>
a{
      text-decoration:none;
      color: #fff;
}
.wrapper{
  padding-top: 30px;
  margin: 0 auto;
  width: 1188px;
  overflow: hidden;
  height: auto;
}

/* 行业动态 */
.contents{
  width: 1188px;
  overflow: hidden;
  height: auto;
  margin: 0 0 0 0;
  background-color: #ffffff;
}
.news_top{
  border-bottom: #BEBCBC 1px solid;
  width: 1185px;
  /* height: 29px; */
  overflow: hidden;
}
.news_title{
  float: left;
  background-color: #577CCF;
  width: 105px;
  height: 40px;
  line-height: 31px;
  /* overflow: hidden; */
}
.news_title h1{
  line-height: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.news_more{
  float: right;
  width: 60px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}
.news_more a{
  color: #000000;
  text-align: center;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
}
.news_mid{
  display: flex;
  border: #BEBCBC 1px solid;
  width: 1185px;
  height: 263px;
  margin-top: 10px;
  overflow: hidden;
}
.news_lb{
    width: 330px;
   height: 233px;
}
.news_lb img{
  margin-top: 15px;
  margin-left: 20px;
   width: 330px;
   height: 233px;
   /* overflow: hidden; */
  }
  .news_list{
    margin-left: 30px;
    margin-top: 18px;
    float: right;
    line-height: 40px;
  }
  .news_list li{
    width: 600px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow :ellipsis;
  }
  .swiper-container{
    margin-top: 15px;
    margin-left: 20px;
    width: 330px;
    height: 233px;
  }

/* 院校库 */
.larger{
	margin: 0 auto;
  margin-top: 60px;
  float: left;
  width: 1186px;
  /* width: 585px; */
  height: auto;
  overflow: hidden;
  display: flex;
}
.larger_top{
  background: url(../../assets/larger_top.jpg) no-repeat 6px center;
  background-color: #F6F6F6;
  border-bottom: #1844A7 2px solid;
  /* width: 895px; */
  width: 585px;
  height: 48px;
  overflow: hidden;
}
.larger_title{
  float: left;
  width: 200px;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
}
.larger_title h1 {
    line-height: 28px;
    color: #0e50a3;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    text-indent: 25px;
    font-family: Microsoft Yahei;
}
.larger_more{
  float: right;
  width: 60px;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
}
.larger_more a:visited {
    COLOR: #000000;
    text-align: center;
    height: 48px;
    font-size: 12px;
    font-weight: 500;
    /* font-family: Microsoft Yahei; */
}
.larger_midk {
	margin-top: 55px;
    border: #cccccc 1px solid;
    width: 583px;
    /* height: auto; */
    height: 350px;
    overflow: hidden;
}
.larger_midk li{
  overflow: hidden;
  white-space:nowrap;
  text-overflow :ellipsis;
  font-size: 16px;
  line-height: 39px;
}


/* 通知 */
.news_left{
  float: left;
  border-bottom: #BEBCBC 1px solid;
  width: 580px;
  /* height: 29px; */
  overflow: hidden;
}
.news_right{
  float: right;
  border-bottom: #BEBCBC 1px solid;
  width: 580px;
  /* height: 29px; */
  overflow: hidden;
}
</style>