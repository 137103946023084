<template>
  <!--页脚-->
<div class="width100 hidden_yh" style="background: #f0f0f0;margin-top: 50px;height:200px">
    <div class="content" style="width:1050px;" >
        <div class="res">
          <div class="font16 c_33" style="margin-top: 6px;">www.xxx.com © Copyright 校企合作 2021-2022</div>
          <div class="font16 c_33" style="margin-top: 6px;">电话：010-1234567</div>
          <div class="font16 c_33" style="margin-top: 6px;"> 手机：123456789</div>
          <div class="font16 c_33" style="margin-top: 6px;">京公网安备 xxxxxxxxxx号 </div>
        </div>
        <div class="logo" style="width:150px;heigth:150px">
          <img src="../../images/xqlogo.jpg" />
          <img src="../../images/2wm.jpg" alt="">
        </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.width100{width:100%!important;display:block;}
.content{
  margin: 50px auto;
  display: flex;
}
.res{
  width: 400px;
}
.logo{
  display: flex;
}
.content img{
  width: 130px;
  height: 130px;
  margin-left: 90px;

  border-radius: 30%;
}
.hidden_yh{overflow:hidden;}
.center_yh{margin:0 auto;}
.font16{font-size:16px!important;}
.c_33{color:#333;}
</style>