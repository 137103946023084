<template>
    <div class="wrapper">
      <div class="cnt">
        <div class="cnt_top">
            <div class="top_title">
              <h1>综合资讯库</h1>
            </div>
            <div class="top_path">
              <h1>当前位置：
                <router-link to="/home"> 首页</router-link>
                 >> 
                <router-link to="/informationdetail">综合资讯库</router-link>
              </h1>
            </div>
        </div>


        <!-- <div style="width:1100px;margin: 0 auto">
          <h3 style="font-weight: 200;">{{information.inforContent}}</h3>
        </div> -->
		
		
		<div class="news_title">
			<h2>{{information.inforTitle}}</h2>
		</div>
		<div class="news_content">
			<div>{{information.inforContent}}</div>
		</div>
		<div class="news_time">{{information.inforTime}}</div>
      </div>
  </div>
</template>

<script>
import {getInforDetail} from '../../api/index'
export default {
  data(){
    return{
      iid:'',
      information:{},
    }
  },
  created(){
    this.iid=this.$route.query.id
    
    this.inforDetail()
  },
  methods:{
    inforDetail(){
      getInforDetail(this.iid).then(res=>{
        console.log(res)
        this.information=res.data
      })
    }
  },
 
}
</script>

<style scoped>
.wrapper {
    MARGIN: 0px auto;
    WIDTH: 1188px;
    height: auto;
    background-color: #FFFFFF;
    overflow: hidden;
}
.cnt {

    width: 1188px;
    height: auto;
    background-color: #ffffff;
    margin: 50px 0 0 0;
    overflow: hidden;
}
.cnt_top {
    border-bottom: #CCCCCC 1px solid;
    width: 1188px;
    height: 49px;
    overflow: hidden;
}
.top_title {
    color: #010204;
    font-size: 22px;
    float: left;
    width: 50%;
    height: 50px;
    overflow: hidden;
}
.top_title h1 {
    color: #373333;
    float: left;
    width: 100%;
    line-height: 20px;
    text-align: left;
    text-indent: 5px;
    font-size: 22px;
    font-weight: 800;
}
.top_path {
    display: block;
    color: #968E8E;
    font-size: 12px;
    height: 50px;
    line-height: 66px;
    width: auto;
    padding-right: 5px;
    overflow: hidden;
}
.top_path h1 {
    color: #968E8E;
    height: 50px;
    line-height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
}
.top_path h1 a:visited {
    color: #968E8E;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}

.itd {
    margin-top: 15px;
    width: 1188px;
    height: auto;
    overflow: hidden;
}
.itd_title {
    float: left;
    background: url(../../assets/itd_title_bg.jpg) no-repeat center top; width: 1185px;
    width: 1185px;
    height: 86px;
    overflow: hidden;
}
.title_h {
    float: left;
    width: 885px;
    height: 86px;
    overflow: hidden;
}
.title_h h1 {
    color: #ffffff;
    float: left;
    width: auto;
    line-height: 56px;
    text-align: left;
    text-indent: 40px;
    font-size: 26px;
    font-weight: 900;
    font-family: Microsoft Yahei;
}
.center {
  display: flex;
    margin-top: 25px;
    background-color: #EDEEF1;
    width: 1185px;
    height: auto;
    overflow: hidden;
}
.center img{
  margin:15px 0 15px 25px;
  width: 204px;
  height: 204px;
}
.right{
  margin-left: 200px;
  margin-top: 20px;
}
.tl {
    font-size: 20px;
    color: #2e3033;
    line-height: 48px;
    text-align: left;
    font-weight: 500;
    overflow: hidden;
}
.lx{
  margin-top: 138px;
  margin-left: 350px;
}

.items_top {
    margin-top: 25px;
    background-color: #EAEAEA;
    width: 1185px;
    height: 39px;
    overflow: hidden;
}
.title_items {
    float: left;
    background: url(../../assets/title_items.jpg) repeat-x left;
    width: auto;
    height: 39px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    overflow: hidden;
}
.title_items h1 {
    float: left;
    padding-left: 35px;
    padding-right: 35px;
    color: #ffffff;
    width: auto;
    line-height: 15px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}


/*	详情页	*/
.news_title{
	margin-top:60px;
	text-align:center;
}
.news_content{
	font-size:18px;
	margin-top:60px;
}
.news_time{
	margin-top:60px;
	float:right;
}
</style>