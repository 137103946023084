// 对axios进行二次封装
import axios from "axios";
axios.defaults.timeout = 5000;  //超时时间设置
axios.defaults.withCredentials = true;  //true允许跨域
//Content-Type 响应头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//基础url
axios.defaults.baseURL = "http://39.108.229.129:82";
//axios.defaults.baseURL = "http://791e8764.r8.vip.cpolar.cn";



// 利用axios对象的方法create，去创建一个axios实例
const requests=axios.create({
    baseURL:"http://localhost:82",
    timeout:5000,
});
//请求拦截 
requests.interceptors.request.use((config)=>{
    return config;
})
//响应拦截
requests.interceptors.response.use((res)=>{
    return res.data;
},(error)=>{
    return Promise.reject(new Error('faile'));
})

/**
   * 封装get方法
   * @param url
   * @param data
   * @returns {Promise}
   */

 export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        params: params
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err)
        })
    })
  }
  
  
  /**
     * 封装post请求
     * @param url
     * @param data
     * @returns {Promise}
     */
  
  export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, data)
        .then(response => {
          resolve(response.data);
        }, err => {
          reject(err)
        })
    })
  }
  
  /*====================重点开始=========================*/
  // const _axios = axios.create(config)
  
  // //异步请求前在header里加入token
  // _axios.interceptors.request.use(
  //   config => {
  //     // 判断是否存在token，如果存在的话，则每个http header都加上token
  //     let token = sessionStorage.getItem('token')
  //     if (!config.headers.hasOwnProperty('token') && token) {
  //       config.headers.token = token
  //     }
  //     return config
  //   },
  //   function (error) {
  //     // 对请求错误做些什么
  //     return Promise.reject(error)
  //   }
  // )
  
  // //异步请求后，判断token是否过期
  // _axios.interceptors.response.use(
  //   response => {
  //     if (response.data.status === 403) {
  //       this.$message.error(Response.data.message)
  //       sessionStorage.removeItem('token')
  //       this.$router.push('/login')
  //     } else {
  //       return response
  //     }
  //   },
  //   error => {
  //     return Promise.reject(error)
  //   }
  // )
  /*====================重点结束=========================*/ 


// 对外暴露
export default requests;