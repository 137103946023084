import Vue from 'vue'
import App from './App.vue' 

//引入路由
import router from "@/router";

//时间格式处理
import moment from 'moment'  //引入

// 挂载  时间处理  2022-06-12T00:00:00.000+0800
Vue.prototype.moment = moment
Vue.filter('dateFormat', function (dateStr, pattern = 'YYYY-MM-DD HH:mm') {
  return moment(dateStr).format(pattern)
})


//路由跳转返回页面顶部
router.beforeEach((to, from, next) => {    
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  render: h => h(App),
  router

}).$mount('#app')
