<template>
	<div style="width: 100px;margin: 0 auto;">
			<el-form
		        ref="ruleForm"
		        :model="ruleForm"
		        
		        :inline="true"
		        label-width="180px"
		        class="demo-ruleForm"
		      >
		    <div>
		          <el-form-item
		            label="学校id:"
		            prop="id"
		          >
		            <el-input
		              v-model="ruleForm.recruitId"
		              placeholder="请填写菜品名称"
		              maxlength="20"
		            />
		          </el-form-item>
			</div>
			<div>
				  <el-form-item
					label="学校图片:"
					prop="region"
					class="uploadImg"
				  >
				<el-upload
				  class="avatar-uploader"
				  action="http://39.108.229.129:82/common/upload"
				  :show-file-list="false"
				  :on-success="handleAvatarSuccess"
				  :before-upload="beforeAvatarUpload">
				  <img v-if="imageUrl" :src="imageUrl" class="avatar">
				  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			</div>
	 <div>
	 <el-form-item>
	 <el-button
	              type="primary"
	              @click="submitForm()"
	            >
	              保存
	            </el-button>
				</el-form-item>
				</div>
				</el-form>
	</div>
</template>

<script>
import {addSchool} from '../../api/index';
  export default {
    data() {
      return {
		
        imageUrl: '',
		ruleForm  : {
			'recruitId':0,
		  'picPath': ''
		},
      };
    },
    methods: {
		
		submitForm(){
			let params = {...this.ruleForm}
			addSchool(params)
		},
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
		this.ruleForm.picPath= res.data
		
		console.log(res)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
  }
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
