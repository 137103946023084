<template>
<div class="wrapper">
  <div class="contents">
    <div class="news_top">
      <div class="news_title">
        <h1>行业动态</h1>
      </div>
      <div class="news_more">
        <a href="#" target>更多>></a>
      </div>
    </div>
    <div class="news_mid">
        <div class="news_lb">
             <img src="@/assets/news_lb/lb1.jpg" alt="">
        </div>
        <div class="news_list" >
            <ul style="list-style-type:none;">
              <div v-for="item in dt" :key="item.id" style="  display: flex;">
                <li>>
                 {{item.con}}
               </li>
               <div style="margin-left:40px">{{item.time}}</div>
              </div>
            </ul>
        </div>
    </div>
  </div>
  
  <div class="larger">
    <!-- 院校库 -->
    <div>
      <div class="larger_top">
        <div class="larger_title">
            <h1>院校库</h1>
        </div>
        <div class="larger_more">
          <router-link to="/school">更多>></router-link>
        </div>
      </div>
      <div class="larger_midk">
		  <img src="../../assets/xx.jpg" style="width: 250px;height:285px;margin-top: 30px;margin-left: 20px;border-radius: 5%;">
          <ul>
            <div v-for="(item,index) in schoolList" :key="item.schoolId" v-if="index<8">
              <li @click="goDetails(item.schoolId)">{{item.schoolName}}</li>
            </div>
          </ul>
      </div>
    </div>
    <!-- 企业库 -->
    <div style="margin-left:15px">
      <div class="larger_top">
        <div class="larger_title">
            <h1>企业库</h1>
        </div>	
        <div class="larger_more">
          <router-link to="/company">更多>></router-link>
        </div>
      </div>
      <div class="larger_midk">
           <ul>
            <div v-for="(item,index) in companyList" :key="item.companyId" v-if="index<8">
              <li @click="goCompanyDetails(item.companyId)">{{item.companyName}}</li>
            </div>
          </ul>
		  <ul style="line-height: 40px;margin-left: 20px;">
		   <img src="../../assets/qy1.jpg" >
		   <img src="../../assets/qy2.jpg" >
		   <img src="../../assets/qy3.jpg" >
		   <img src="../../assets/qy4.jpg" >
		  </ul>
      </div>
    </div>
  </div>
  <!-- 校园招聘 -->
  <div class="invite">
	  <div class="invite_top">
		  <div class="t_tl">
			  <h1>校园招聘</h1>
		  </div>
		  <div class="t_more">
			  <router-link to="/recruit"> 更多>></router-link>
		  </div>
	  </div>
	  <div class="invite_bottom">
		  <div class="i_ctn">
			  <ul>
				  <div v-for="(item,index) in recruitList" :key="item.recruitId" v-if="index<4">
					  <li >
						  <img :src="baseUrl+item.picPath" style="width: 285px;height: 180px;" @click="toDetails(item.recruitId)">
						  <h1>{{item.recruitTitle}}</h1>
						  <h2>开始时间：{{item.startTime | dateFormat}}</h2>
						  <h3>结束时间：{{item.endTime | dateFormat}}</h3>
					  </li>
				  </div>
			  </ul>
		  </div>
	  </div>
  </div>
</div>

</template>

<script>
import {getSchoolList,getCompanyList,getRecruitList} from '../../api/index';
export default {
    data(){
      return{
		  baseUrl:"http://39.108.229.129:82",
        schoolList:[],
        companyList:[],
		// 招聘
		recruitList:[],
        images:[],
        dt:[
          {id:1,con:"新闻发布会 | 介绍教育系统学习宣传贯彻落实新修订《中华人民共和国职业教育法》有关情况",time:"2022-05-17"},
          {id:2,con:"快速优化新时代职教教师队伍结构",time:"2022-02-17"},
          {id:3,con:"2021年全国教育事业统计主要结果发布",time:"2022-04-17"},
          {id:4,con:"教育部：2022年职业教育重点工作",time:"2022-03-17"},
          {id:5,con:"教育部2022年工作要点",time:"2022-01-17"}
          ]
      }
    },
    created(){
      this.getSchool();
      this.getCompany();
	  this.getRecruit();
    },
    methods:{
		//时间格式
		getTime(val){
		  return String(val).substr(0,10);
		},
      //获得院校库
      getSchool(){
        getSchoolList().then(res=>{
          console.log(res)
          this.schoolList=res.data
        })
      },
      //获得企业库
      getCompany(){
        getCompanyList().then(res=>{
          console.log(res)
          this.companyList=res.data
        })
      },
      //院校详情
      goDetails(id){
        this.$router.push("/schooldetail?id="+id)
      },
	  //企业详情
	  goCompanyDetails(id){
	    this.$router.push("/companydetail?id="+id)
	  },
	  //校园招聘会
	  getRecruit(){
	  	getRecruitList().then(res=>{
	  		console.log(res)
	  		this.recruitList = res.data
	  	})
	  },
	  //校园招聘详情
	  toDetails(id){
	    this.$router.push("/recruitdetail?id="+id)
	  },
    },
    mounted(){
       
    }
}
</script>

<style scoped>
a{
      text-decoration:none;
      color: #fff;
}
.wrapper{
  padding-top: 30px;
  margin: 0 auto;
  width: 1188px;
  overflow: hidden;
  height: auto;
}

/* 行业动态 */
.contents{
  width: 1188px;
  overflow: hidden;
  height: auto;
  margin: 0 0 0 0;
  background-color: #ffffff;
}
.news_top{
  border-bottom: #BEBCBC 1px solid;
  width: 1185px;
  /* height: 29px; */
  overflow: hidden;
}
.news_title{
  float: left;
  background-color: #577CCF;
  width: 105px;
  height: 40px;
  line-height: 31px;
  /* overflow: hidden; */
}
.news_title h1{
  line-height: 20px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.news_more{
  float: right;
  width: 60px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}
.news_more a{
  color: #000000;
  text-align: center;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
}
.news_mid{
  display: flex;
  border: #BEBCBC 1px solid;
  width: 1185px;
  height: 263px;
  margin-top: 10px;
  overflow: hidden;
}
.news_lb{
    width: 330px;
   height: 233px;
}
.news_lb img{
  margin-top: 15px;
  margin-left: 20px;
   width: 330px;
   height: 233px;
   /* overflow: hidden; */
  }
  .news_list{
    margin-left: 30px;
    margin-top: 18px;
    float: right;
    line-height: 40px;
  }
  .news_list li{
    width: 600px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow :ellipsis;
  }
  .swiper-container{
    margin-top: 15px;
    margin-left: 20px;
    width: 330px;
    height: 233px;
  }

/* 院校库 */
.larger{
  float: left;
  width: 1186px;
  height: auto;
  margin: 20px 0 0 0;
  /* width: 585px; */
  overflow: hidden;
  display: flex;
}
.larger_top{
  background: url(../../assets/larger_top.jpg) no-repeat 6px center;
  background-color: #F6F6F6;
  border-bottom: #1844A7 2px solid;
  /* width: 895px; */
  width: 585px;
  height: 48px;
  overflow: hidden;
}
.larger_title{
  float: left;
  width: 200px;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
}
.larger_title h1 {
    line-height: 28px;
    color: #0e50a3;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    text-indent: 25px;
    font-family: Microsoft Yahei;
}
.larger_more{
  float: right;
  width: 60px;
  height: 48px;
  line-height: 48px;
  overflow: hidden;
}
.larger_more a:visited {
    COLOR: #000000;
    text-align: center;
    height: 48px;
    font-size: 18px;
    font-weight: 500;
    /* font-family: Microsoft Yahei; */
}
.larger_midk {
  margin-top: 15px;
    border: #cccccc 1px solid;
    width: 583px;
    /* height: auto; */
    height: 350px;
    overflow: hidden;
}
.larger_midk ul {
	margin-top: 30px;
	float: left;
	width: 230px;
}
.larger_midk li{
  overflow: hidden;
  white-space:nowrap;
  text-overflow :ellipsis;
  font-size: 16px;
  line-height: 35px;
}
/* 校园招聘 */
.invite{
	width: 1186px;
	height: auto;
	margin: 0 0 0 0;
	padding-top: 20px;
	  overflow: hidden;
	/* width: 1185px;
	/* height: 500px; */
	/* margin: 0 auto; */
} 
.invite_top {
	/* margin-top: 20px; */
    border-bottom: #B2B2B2 1px solid;
    width: 1188px;
    height: 41px;
    overflow: hidden;
}
.t_tl {
    float: left;
    height: 41px;
    line-height: 41px;
    width: 140px;
    overflow: hidden;
}
.t_tl h1 {
    float: left;
    height: 41px;
    line-height: 20px;
    width: 140px;
    color: #0e50a3;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    text-align: left;
    text-indent: 5px;
    overflow: hidden;
}
.t_more {
    float: right;
    width: 60px;
    height: 41px;
    line-height: 41px;
    overflow: hidden;
}
.t_more a {
    COLOR: #000000;
    text-align: center;
    height: 41px;
    font-size: 15px;
    font-weight: 500;
}
.invite_bottom {
    width: 1188px;
    height: auto;
    overflow: hidden;
}
.i_ctn {
    margin: 0 auto;
    width: 1204px;
    height: auto;
}
.i_ctn ul {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.i_ctn ul li {
    float: left;
    margin: 10px 55px 0 -40px;
    background-color: #E6E5E5;
    width: 285px;
    height: 326px;
    text-align: center;
    overflow: hidden;
}
.i_ctn ul li h1 {
    margin: 0 auto;
    width: 275px;
    height: 38px;
    line-height: 38px;
    text-align: left;
    text-indent: 20px;
	font-size: 15px;
	overflow: hidden;
	white-space:nowrap;
	text-overflow :ellipsis;
	
}
.i_ctn ul li h1 a:visited {
    color: #000000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
}
.i_ctn ul li h2 {
    margin: 0 auto;
    width: 285px;
    background: url(../../assets/date.png) no-repeat 20px center;
    height: 30px;
    line-height: 30px;
    text-align: left;
    text-indent: 42px;
	font-size: 13px;
}
.i_ctn ul li h2 a:visited {
    color: #000000;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
}
.i_ctn ul li h3 {
    margin: 0 auto;
    width: 285px;
    background: url(../../assets/date.png) no-repeat 20px center;
    height: 30px;
    line-height: 30px;
    text-align: left;
    text-indent: 42px;
	font-size: 13px;
}
#pic_meet_list_invite ul li h3 a:visited {
    color: #5E5E5E;
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    font-family: Microsoft Yahei;
}
</style>