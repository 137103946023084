<template>
	<div class="rgs" style="width: 1100px;margin: 0 auto;">
		<div>
			<div style="width: 1100px;height: 50px;background-color:#E3E1E2;margin-bottom: -20px;"><h2 style="margin-left: 20px;line-height: 50px;">请填写注册信息</h2></div>
			<div style="display: flex;">
				<div>
					<div style="display: flex;">
						<div class="title">用户名：*</div>
						<input type="text" v-model="form.username"/>
					</div>
					<div style="display: flex;">
						<div class="title">密码：*</div>
						<input type="password" v-model="form.password" />
					</div>
					<div style="display: flex;">
						<div class="title">归属分类：*</div>
						<select style="width: 100px;height: 30px;" v-model="type">
						  <option value ="2">企业</option>
						  <option value ="1">院校</option>
						</select>
					</div>
					<div style="display: flex;">
						<div class="title">单位名称：*</div>
						<input type="text"  v-model="form.schoolName"/>
					</div>
					<div style="display: flex;">
						<div class="title">联系人：</div>
						<input type="text"  v-model="form.schoolContact"/>
					</div>
					<div style="display: flex;">
						<div class="title">地址：</div>
						<input type="text"  v-model="form.schoolAddress"/>
					</div>
					<div style="display: flex;">
						<div class="title">电话：</div>
						<input type="text"  v-model="form.schoolTel"/>
					</div>
					<div style="display: flex;">
						<div class="title">邮箱：</div>
						<input type="text"  v-model="form.schoolEmail"/>
					</div>
				</div>
				<div style="margin-top: 80px;margin-left: 70px;">
					<img src="../../assets/xqhz.jpg" style="width: 600px;height: 400px;">
					<div style="margin-top: 50px;margin-left: 50px;color: #FF0000;">带*为必填项！</div>
				</div>
			</div>
			<button type="button" style="width: 200px;height: 40px;margin-left: 150px;margin-top: 40px;" @click="register">注册</button>
		</div>
	</div>
</template>

<script>
import {saveOrUpdateSchool,saveOrUpdateCompany} from '../../api/index'
	export default{
		data(){
			return{
				form:{
					'username':'',
					'password':'',
					'schoolName':'',
					'schoolContact':'',
					'schoolAddress':'',
					'schoolTel':'',
					'schoolEmail':''
				},
				type:''
			}
		},
		methods:{
			register(){
				if(this.form.username==''||this.form.password==''||this.form.schoolName==''||this.type==''){
					alert("必填项未填")
				}else{
					if(this.type==1){
						let params={...this.form}
						saveOrUpdateSchool(params).then(res=>{
							console.log(res)
							if(res.code==1){
								alert("注册成功")
								this.$router.push("/login")
							}
						})
					}
					if(this.type==2){
						let params={
							'username':this.form.username,
							'password':this.form.password,
							'companyName':this.form.schoolName,
							'companyContact':this.form.schoolContact,
							'companyAddress':this.form.schoolAddress,
							'companyTel':this.form.schoolTel,
							'companyEmail':this.form.schoolEmail
						}
						saveOrUpdateCompany(params).then(res=>{
							console.log(res)
							if(res.code==1){
								alert("注册成功")
								this.$router.push("/login")
							}
						})
					}
				}
			
			}
		}
	}
</script>

<style scoped lang="less">
.rgs{
	.title{
		text-align: center;
		line-height: 30px;
		width: 100px;
		height: 30px;
		background-color:#E3E1E2;
		margin-top: 40px;
		}
		input{
			width: 300px;
			margin-left: 20px;
			margin-top: 40px;
		}
		select{
			margin-top: 40px;
			margin-left: 20px;
		}
}
</style>
