<template>
	<div>
	<div class="user">
		<div style="width: 500px;margin: 0 auto;">
		<h1 style="text-align: center;margin-bottom: 40px;">用户信息</h1>
			<div v-if="types==2">
				<el-form ref="form" :model="form" label-width="80px">
				  <el-form-item label="企业logo" >
					<div class="singer-img">
						<img :src="baseUrl+form.companyPath" style="width: 150px;height: 150px;" @click="dialogFormVisible=true">
					</div>
						<!-- <el-upload :action="uploadUrl(scope.row.id)" :before-upload="beforeAvatarUpload" 
							:on-success="handleAvatarSuccess">
							<el-button size="mini">更新图片</el-button>
						</el-upload> -->
				  </el-form-item>
				  <el-form-item label="企业名称">
					<el-input v-model="form.companyName" ></el-input>
				  </el-form-item>
				  <el-form-item label="地址">
					<el-input v-model="form.companyAddress" ></el-input>
				  </el-form-item>
				  <el-form-item label="企业类型">
					{{form.companyCharacter}}
				  </el-form-item>
				  <el-form-item label="联系人">
					<el-input v-model="form.companyContact" ></el-input>
				  </el-form-item>
				  <el-form-item label="电话">
					<el-input v-model="form.companyTel"></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" @click="onSubmit2">保存</el-button>
					<el-button @click="loginOut">退出登录</el-button>
				  </el-form-item>
				</el-form>
			</div>
			<div v-if="types==1">
				<el-form ref="form" :model="form" label-width="80px">
				  <el-form-item label="学校logo" >
					<div class="singer-img">
						<img :src="baseUrl+form.schoolPath" style="width: 150px;height: 150px;" @click="dialogFormVisible=true">
					</div>
						<!-- <el-upload :action="uploadUrl(scope.row.id)" :before-upload="beforeAvatarUpload" 
							:on-success="handleAvatarSuccess">
							<el-button size="mini">更新图片</el-button>
						</el-upload> -->
				  </el-form-item>
				  <el-form-item label="学校名称">
					<el-input v-model="form.schoolName" ></el-input>
				  </el-form-item>
				  <el-form-item label="地址">
					<el-input v-model="form.schoolAddress" ></el-input>
				  </el-form-item>
				  <el-form-item label="学校类型">
					{{form.schoolCharacter}}
				  </el-form-item>
				  <el-form-item label="联系人">
					<el-input v-model="form.schoolContact"></el-input>
				  </el-form-item>
				  <el-form-item label="电话">
					<el-input v-model="form.schoolTel" ></el-input>
				  </el-form-item>
				  <el-form-item>
					<el-button type="primary" @click="onSubmit1">保存</el-button>
					<el-button @click="loginOut">退出登录</el-button>
				  </el-form-item>
				</el-form>
			</div>
		</div>
	</div>
	
		<el-dialog title="上传头像" :visible.sync="dialogFormVisible" style="width: 500px;margin: 0 auto;">
		  	<el-upload
			style="margin: 0 auto;"
		  	  class="avatar-uploader"
		  	  action="http://39.108.229.129:82/common/upload"
		  	  :show-file-list="false"
		  	  :on-success="handleAvatarSuccess"
		  	  :before-upload="beforeAvatarUpload">
		  	  <img v-if="imageUrl" :src="imageUrl" class="avatar">
		  	  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
		  	</el-upload>
		  <div slot="footer" class="dialog-footer">
			<el-button @click="qx">取 消</el-button>
			<el-button type="primary" @click="uploadAvctor">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
import {getInfo,update,saveOrUpdateSchool,saveOrUpdateCompany} from '../../api/index'
	export default{
		data(){
			return{
				baseUrl:"http://39.108.229.129:82",
				form:{
					
				},
				types:'',
				 dialogFormVisible: false,
				 imageUrl: '',
				 pic:''
			}
		},
		created() {
			this.getUserInfo()		
		},
		methods:{
			getUserInfo(){
				getInfo(sessionStorage.getItem("token")).then(res=>{
					console.log(res)
					this.form=res.data.user
					this.types=res.data.userType
				})
			},
			loginOut(){
				sessionStorage.removeItem("token")
				sessionStorage.removeItem("id")
				this.$router.push("/home")
			},
			uploadAvctor(){
				if(this.pic==''){
					alert("请上传头像")
				}
				else{
					let type=sessionStorage.getItem("userType")
					let id=sessionStorage.getItem("id")
					update(this.pic,type,id).then(res=>{
						console.log(res)
					})
					this.pic=''
					// this.getUserInfo()
					this.dialogFormVisible = false
					location. reload()
				}				
			},
			qx(){
				this.pic='',
				this.dialogFormVisible = false
			},
			//上传
			handleAvatarSuccess(res, file) {
			  this.imageUrl = URL.createObjectURL(file.raw);
					this.pic= res.data
					
					console.log(res)
			},
			beforeAvatarUpload(file) {
			  const isJPG = (file.type === 'image/jpeg')||(file.type === 'image/png');
			  const isLt2M = file.size / 1024 / 1024 < 2;
			
			  if (!isJPG) {
			    this.$message.error('上传头像图片只能是 JPG或PNG格式!');
			  }
			  if (!isLt2M) {
			    this.$message.error('上传头像图片大小不能超过 2MB!');
			  }
			  return isJPG && isLt2M;
			},
			//修改数据
			onSubmit1(){
				let params={...this.form}
				saveOrUpdateSchool(params).then(res=>{
					console.log(res)
					if(res.code==1){
						alert("修改成功")
					}
				})
				location. reload()
			},
			onSubmit2(){
				let params={...this.form}
				saveOrUpdateCompany(params).then(res=>{
					console.log(res)
					if(res.code==1){
						alert("修改成功")
					}
				})
				location. reload()
			}
			
		}
	}
</script>

<style scoped lang="less">
 .singer-img{
		width: 150px;
		height:150px;
	
	}
.user{
  border:1px solid #dccfcf;
  width: 1186px;
  margin:40px auto;
  padding: 35px 80px 15px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 25px #909399;
  background-color:rgba(255,255,255,0.7);
}
.user .el-input{
	width: 500px;
}
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
