<template>
  <div class="wrapper">
	  <div style="display: flex;">
	  <div>
		  <div class="cnt_top">
			  <div class="top_title">
				<h1>院校资源库</h1>
			  </div>
			  <div class="top_path">
				<h1>当前位置：
				  <router-link to="/home"> 首页</router-link>
				   >> 
				  <router-link to="/school">院校资源库</router-link>
				</h1>
			  </div>
		  </div>
		  <div style="margin-top: 20px;">
			  <el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="按院校名称:">
				  <el-input v-model="form.schoolName"></el-input>
				</el-form-item>
				<el-form-item label="所在省份:">
				  <el-input v-model="form.schoolProvince" style="width: 218px;"></el-input>
				</el-form-item>
				<el-form-item label="性质:">
				  <el-select v-model="form.schoolCharacter">
					<el-option label="请选择" value=""></el-option>
					<el-option label="公办" value="公办"></el-option>
					<el-option label="民办" value="民办"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="在校生人数:">
				  <el-select v-model="form.schoolScale">
					  <el-option label="请选择" value=""></el-option>
					<el-option label="1-1000人" value="1-1000人"></el-option>
					<el-option label="1000-5000人" value="1000-5000人"></el-option>
					<el-option label="5000-10000人" value="5000-10000人"></el-option>
					<el-option label="10000以上" value="10000以上"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" @click="search" style="margin-left: 250px;width: 200px;">搜索</el-button>
				</el-form-item>
			  </el-form>
		  </div>
	  </div>
	  <div cnt_right style="width: 300px;margin-top: -22px;margin-left: 10px;">
		  <div>
			<h3 style="border-bottom: #409EFF 3px solid;line-height: 50px;text-align: center;color: #4290F7;"><img src="../../assets/fb.png" style="width: 20px;height: 20px;margin-right: 10px;margin-bottom: -3px;" >我要发布</h3>
		  </div>
		  <div style="display: flex;flex-direction: column;">
			  <button @click="toFabu" style="height: 60px;background-color: #577CCF;display: flex;flex-direction: column;" type="button"><img src="../../assets/fb2.png" style="width: 30px;height: 30px;margin: 0 auto;" ><div style="margin: 0 auto;color: #CCCCCC;font-size: 16px;">校招会发布</div></button>
			  <button @click="toHzFabu" style="height: 60px;margin-top: 20px;background-color: #577CCF;display: flex;flex-direction: column;" type="button"><img src="../../assets/hz.png" style="width: 30px;height: 30px;margin: 0 auto;"><div style="margin: 0 auto;color: #CCCCCC;font-size: 16px;">校企合作项目发布</div></button>
		  </div>
	  </div>
	  </div>
	  <div style="border-bottom: 1px solid;color: #CCCCCC;"></div>
	  <div class="companylist" v-for="item in school" :key="item.schoolId">
		  <div style="width: 1186px;display: flex;margin-top: 50px;"  >
			  <div style="width: 100px;height: 100px;">
				<img :src="baseUrl+item.schoolPath" style="width: 100px;height: 100px;" @click="goSchoolDetails(item.schoolId)">
			  </div>
			  <div>
				  <h2 style="margin-left: 50px;margin-top: -1px;" @click="goSchoolDetails(item.schoolId)">{{item.schoolName}}</h2>
				  <div style="display: flex;">
					  <div style="margin-left: 50px;">性质：{{item.schoolCharacter}}</div>
					  <div style="margin-left: 50px;">规模(人数)：{{item.schoolScale}}</div>
				  </div>
				  <div style="margin-left: 50px;margin-top: 5px;">归属地：{{item.schoolProvince}} {{item.schoolCity}}</div>
			  </div>
		  </div>
		  <div style="border-bottom: 1px solid;color: #CCCCCC;margin-top: 40px;"></div>
	  </div>
	  <div class="block"  style="margin: 0 auto;width: 400px;">
	      <el-pagination
	        @size-change="handleSizeChange"
	        @current-change="handleCurrentChange"
	        :current-page.sync="currentPage"
	        :page-size="this.pageSize"
	        layout="prev, pager, next, jumper"
	  			:total="this.total"
	  			>
	      </el-pagination>
	    </div>
  </div>
</template>

<script>
import {getSchoolPageList} from '../../api/index';
export default {
	data(){
		return{
			baseUrl:"http://39.108.229.129:82",
			form: {
			 'schoolName':"",
			 'schoolProvince':"",
			 'schoolCharacter':"",
			 'schoolScale':""
			},
			school:[],
			currentPage: 1,
			pageSize:5,
			total:0
		}
	},
	created() {
		this.schoolList()
	},
	methods:{
		search(){
			let params={...this.form}
			console.log(this.form)
			getSchoolPageList(this.currentPage,this.pageSize,params).then(res=>{
				console.log(res)
				this.school=res.data.records
				this.total=res.data.total
			})
		},
		schoolList(){
			let params={...this.form}
			getSchoolPageList(this.currentPage,this.pageSize,params).then(res=>{
				console.log(res)
				this.school=res.data.records
				this.total=res.data.total
			})
		},
		 //院校详情
		goSchoolDetails(id){
		  this.$router.push("/schooldetail?id="+id)
		},
		//校招发布
		toFabu(){
			if(sessionStorage.getItem("token")==null){
				this.$router.push("/login")
			}else if(sessionStorage.getItem("userType")==2){
				alert("企业无法发布")
			}else{
				this.$router.push("/fabu")
			}
		},
		//校企合作发布
		toHzFabu(){
			if(sessionStorage.getItem("token")==null){
				this.$router.push("/login")
			}else{
				this.$router.push("/hzfabu")
			}
		},
		//分页
		 handleSizeChange(val) {
		        console.log(`每页 ${val} 条`);
			},
		  handleCurrentChange(val) {
			  this.currentPage=val
			  let params={...this.form}
			console.log(`当前页: ${val}`);
			getSchoolPageList(this.currentPage,this.pageSize,params).then(res=>{
				console.log(res)
				this.school=res.data.records
				this.total=res.data.total
			})
			
		  }
	}
}
</script>

<style scoped>
.wrapper {
	padding-top: 40px;
    MARGIN: 0px auto;
    WIDTH: 1186px;
    height: auto;
    background-color: #FFFFFF;
    overflow: hidden;
}
.cnt {
    width: 1188px;
    height: auto;
    background-color: #ffffff;
    margin: 50px 0 0 0;
    overflow: hidden;
}
.cnt_top {
    border-bottom: #CCCCCC 1px solid;
    width: 885px;
    height: 49px;
    overflow: hidden;
}
.top_title {
    color: #010204;
    font-size: 22px;
    float: left;
    width: 50%;
    height: 50px;
    overflow: hidden;
}
.top_title h1 {
    color: #373333;
    float: left;
    width: 100%;
    line-height: 20px;
    text-align: left;
    text-indent: 5px;
    font-size: 22px;
    font-weight: 800;
}
.top_path {
    display: block;
    color: #968E8E;
    font-size: 12px;
    height: 50px;
    line-height: 66px;
    width: auto;
    padding-right: 5px;
    overflow: hidden;
}
.top_path h1 {
    color: #968E8E;
    height: 50px;
    line-height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
}
.top_path h1 a:visited {
    color: #968E8E;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}
</style>