	<template>
	<div class="wrapper">
		<div class="cnt">
			<div class="cnt_top">
				<div class="top_title">
				  <h1>校园招聘会</h1>
				</div>
				<div class="top_path">
				  <h1>当前位置：
					<router-link to="/home"> 首页</router-link>
					 >> 
					<router-link to="/recruit">校园招聘会</router-link>
				  </h1>
				</div>
			</div>
			<div class="larger_midk" v-for="item in recruitList"
						:key="item.recruitId"  @click="toDetails(item.recruitId)">
				<div class="image">
					<img :src="baseUrl+item.picPath"/>
				</div>
				<div class="recruit_content">
					<div >
						<div>{{item.recruitTitle}}</div>
						<div>主办单位：{{item.depart}}</div>
						<div>开始时间：{{item.startTime | dateFormat}}</div>
						<div>结束时间：{{item.endTime | dateFormat}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getRecruitList} from '../../api/index';
export default{
	data(){
		return{
			baseUrl:"http://39.108.229.129:82",
			recruitList:[]
		}
	},
	created(){
		this.getRecruit();
	},
	methods:{
		//时间
		getTime(val){
		  return String(val).substr(0,10);
		},
		//校园招聘会
		getRecruit(){
			getRecruitList().then(res=>{
				console.log(res)
				this.recruitList = res.data
			})
		},
		//校园招聘详情
		toDetails(id){
		  this.$router.push("/recruitdetail?id="+id)
		},
	}
}
</script>

<style scoped>
.wrapper {
    MARGIN: 0px auto;
    WIDTH: 1188px;
    height: auto;
    background-color: #FFFFFF;
    overflow: hidden;
}
.cnt {

    width: 1188px;
    height: auto;
    background-color: #ffffff;
    margin: 50px 0 0 0;
    overflow: hidden;
}
.cnt_top {
    border-bottom: #CCCCCC 1px solid;
    width: 1188px;
    height: 49px;
    overflow: hidden;
}
.top_title {
    color: #010204;
    font-size: 22px;
    float: left;
    width: 50%;
    height: 50px;
    overflow: hidden;
}
.top_title h1 {
    color: #373333;
    float: left;
    width: 100%;
    line-height: 20px;
    text-align: left;
    text-indent: 5px;
    font-size: 22px;
    font-weight: 800;
}
.top_path {
    display: block;
    color: #968E8E;
    font-size: 12px;
    height: 50px;
    line-height: 66px;
    width: auto;
    padding-right: 5px;
    overflow: hidden;
}
.top_path h1 {
    color: #968E8E;
    height: 50px;
    line-height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
}
.top_path h1 a:visited {
    color: #968E8E;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}

	
	/* 校园招聘会 */
.larger_midk {
    margin-top: 30px;
	border: #cccccc 1px solid;
	width:1180px;
	/* height: auto; */
	height: 240px;
	overflow: hidden;
	/* margin-left:30px; */
	border-radius: 12px;
}
.larger_midk img{
	width: 330px;
	height: 230px;
	margin-top: 5px;
	margin-left: 5px;
	border-radius: 12px;
}
.image{
	float: left;
}

.recruit_content{
	float: left;
	margin-top: 20px;
	margin-left: 40px;
	line-height: 50px;
}
</style>
