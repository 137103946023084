<template>
	<div class="wrapper">
		  <div class="cnt_top">
		      <div class="top_title">
		        <h1>院校合作项目</h1>
		      </div>
		      <div class="top_path">
		        <h1>当前位置：
		          <router-link to="/home"> 首页</router-link>
		           >> 
		          <router-link to="/projectdetail">院校合作项目</router-link>
		        </h1>
		      </div>
		  </div>
		  <div class="cnt_center">
			  <h1 style="text-align: center;color:#9A6E3A">{{project.projectTitle}}</h1>
			  <div style="text-align: center;font-size: 14px;color:red">
				  [项目单位名称：{{project.projectDepart}} | 发布时间：{{project.projectTime}}]
			  </div>
		  </div>
		  <div class="cnt_bottom">
			<div v-html="project.projectContent"></div>
		  </div>
	</div>
</template>

<script>
import {getProjectDetail} from '../../api/index'
export default{
	data(){
		return{
			pid:'',
			project:[]
		}
	},
	created() {
		this.pid=this.$route.query.id
		this.projectDetail()
	},
	methods:{
		projectDetail(){
		  getProjectDetail(this.pid).then(res=>{
		    console.log(res)
			this.project=res.data
		  })
		}
	}
}
</script>

<style scoped>
	.wrapper {
	    MARGIN: 0px auto;
	    WIDTH: 1188px;
	    height: auto;
	    background-color: #FFFFFF;
	    overflow: hidden;
		padding-top: 40px;
	}
	.cnt {
	
	    width: 1188px;
	    height: auto;
	    background-color: #ffffff;
	    margin: 50px 0 0 0;
	    overflow: hidden;
	}
	.cnt_top {
	    border-bottom: #CCCCCC 1px solid;
	    width: 1188px;
	    height: 49px;
	    overflow: hidden;
	}
	.top_title {
	    color: #010204;
	    font-size: 22px;
	    float: left;
	    width: 50%;
	    height: 50px;
	    overflow: hidden;
	}
	.top_title h1 {
	    color: #373333;
	    float: left;
	    width: 100%;
	    line-height: 20px;
	    text-align: left;
	    text-indent: 5px;
	    font-size: 22px;
	    font-weight: 800;
	}
	.top_path {
	    display: block;
	    color: #968E8E;
	    font-size: 12px;
	    height: 50px;
	    line-height: 66px;
	    width: auto;
	    padding-right: 5px;
	    overflow: hidden;
	}
	.top_path h1 {
	    color: #968E8E;
	    height: 50px;
	    line-height: 20px;
	    text-align: right;
	    font-size: 18px;
	    font-weight: 500;
	}
	.top_path h1 a:visited {
	    color: #968E8E;
	    text-decoration: none;
	    font-size: 12px;
	    font-weight: 500;
	}
</style>
