<template>
<div>
    <div class="width100" style="height: 35px;background: #f0f0f0;">
        <div class=" center_yh font14 c_66" style="height: 25px;line-height: 25px;width:1050px">
            <font class="left_yh" style="line-height: 35px;">欢迎来到校企合作网！</font>
            <div class="right_yh" style="display: flex;">
				<div style="margin-right: 40px;line-height: 35px;font-size: 16px;" @click="getInfo()">
					用户信息
				</div>
				<div style="line-height: 35px;">
					<router-link to="/login" style="margin-right: 10px;">登录</router-link> | <router-link to="/register" style="margin-left: 10px;">注册</router-link>
				</div>
            </div>
        </div>
    </div>
    <div class="logo">
        <img src="../../images/xqlogo.jpg"/>
    </div>
    <div class="nav">
      <ul style="list-style-type:none; width:1200px">
          <li><router-link to="/home">首页</router-link></li>
          <li><router-link to="/advisory">综合资讯</router-link></li>
          <li><router-link to="/school">院校库</router-link></li>
          <li><router-link to="/company">企业库</router-link></li>
          <!-- <li><router-link to="/home">校企合作</router-link></li> -->
          <!-- <li><router-link to="/upload">校园招聘会</router-link></li> -->
		  <li><router-link to="/recruit">校园招聘会</router-link></li>
          <li><router-link to="/cooperation">校企合作项目</router-link></li>
      </ul>
    </div>
    <div class="lb" style="width:100%; margin-top: 1px;">
        <div class="swiper-container">
            <div class="swiper-wrapper" >
                <div class="swiper-slide" v-for="item in images" :key="item.id">
                    <img :src="item.url" width="100%" height="100%" alt="" />
                </div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</div>

</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.css";
export default {

    data(){
        return{
			token:'',
            images: [
                
            ] 
        }
    },
	methods:{
		getInfo(){
			// console.log("aaa")
			if(sessionStorage.getItem("token")==null){
				this.$router.push("/login")
			}else{
				this.$router.push("/user")
			}
		}
	},
	created() {
		this.token=sessionStorage.getItem("token")
	},
    updated(){
         var mySwiper = new Swiper ('.swiper-container', {
            loop: true, // 循环模式选项
            autoplay: {
                delay:2000,
            },
            // 如果需要分页器
            pagination: {
            el: '.swiper-pagination',
            },
            // 如果需要前进后退按钮
            navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            },
            
        })        
    },
    mounted(){
		
        this.images=[ {id:1, url: require("@/assets/lb1.jpg")},
                {id:2, url: require("@/assets/lb2.jpg")},
                {id:3, url: require("@/assets/lb3.jpg")},
                {id:4, url: require("@/assets/lb4.jpg")}]
				
				var mySwiper = new Swiper ('.swiper', {
				   
				    loop: true, // 循环模式选项
				    
				    // 如果需要分页器
				    pagination: {
				      el: '.swiper-pagination',
				    },
				    
				    // 如果需要前进后退按钮
				    navigation: {
				      nextEl: '.swiper-button-next',
				      prevEl: '.swiper-button-prev',
				    },
				    
				  
				  })        
    }
}
</script>





<style lang="less">
a{
    text-decoration:none;
    color: #fff;
}
a:hover{
    color: black;
}
body{
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
} 
.width100{width:100%!important;display:block;}
.font14{font-size:16px!important;}
.c_66{color:#666;}
.left_yh{float:left;}
.center_yh{margin:0 auto;}
.right_yh{
    float: right;
}
.right_yh a{
    color: black;
}
.logo{
    width: 1100px;
    margin: 0 auto;
}
.logo img{
    width: 180px;
    height: 180px;
}

.nav{
    width: 100%;
    height: 50px;
    background:#3762c0;
}
.nav ul{
    margin:  0 auto;
}

.nav li {
    text-align: center;
    width: 190px;
    line-height: 50px;
    height: 50px;
    margin: 0 auto;
    /* margin-top: 15px; */
    float: left;
   /* margin-right: 110px; */
   font-size: 13pt;
   color: white;
}
 .swiper-container {
    margin: 0 auto;
    width: 1680px;
    height: 300px;
}  
</style>