// API统一管理
// import requests from './request';
import { get, post} from './request'

//院校库
export const getSchoolList = () =>get(`school/list`)
//分页模糊搜索查询
export const getSchoolPageList = (pageNo,pageSize,params) =>get(`school/pageList?pageNo=${pageNo}&pageSize=${pageSize}`,params)

//院校详情
export const getSchoolOne = (sid) =>get(`school/one?id=${sid}`)
//添加院校/修改
export const saveOrUpdateSchool = (params) =>post(`school/saveOrUpdate`,params)




//企业库
export const getCompanyList = () =>get(`company/list`)
//添加或修改企业
export const saveOrUpdateCompany = (params) =>post(`company/saveOrUpdate`,params)
//分页模糊搜索查询
export const getCompanyPageList = (pageNo,pageSize,params) =>get(`company/pageList?pageNo=${pageNo}&pageSize=${pageSize}`,params)

//企业详情
export const getCompanyOne = (cid) =>get(`company/one?id=${cid}`)

//资讯表
export const getInforList = () => get(`information/list`)

export const getMorePage = (type,pageNo,pageSize) => get(`information/pageList?type=${type}&pageNo=${pageNo}&pageSize=${pageSize}`)

//资讯详情
export const getInforDetail = (iid) => get(`information/detail?id=${iid}`)



//校园招聘会
export const getRecruitList = () => get(`recruit/list`)

//校园招聘会详情
export const getRecruitDetail = (rid) => get(`recruit/detail?id=${rid}`)
//校招发布或修改
export const saveOrUpdateRecruit=(params)=>post(`recruit/saveOrUpdate`,params)



//校企合作
//发布项目/修改
export const addProject = (params) =>post(`project/saveOrUpdate`,params)
//项目列表
export const getProjectList = (type,currentPage,pageSize) => get(`project/list?type=${type}&pageNo=${currentPage}&pageSize=${pageSize}`)
//项目详情
export const getProjectDetail = (pid) => get(`project/one?id=${pid}`)



//登录
export const login = (params) =>post(`common/login`,params)
//用户信息
export const getInfo = (token) =>get(`common/getInfo?token=${token}`)
//修改头像
export const update=(pic,type,id)=>post(`common/updateImg?pic=${pic}&type=${type}&id=${id}`)