<template>
	<div class="wrapper">
		<div class="cnt">
			<div class="cnt_top">
				<div class="top_title">
				  <h1>校园招聘会</h1>
				</div>
				<div class="top_path">
				  <h1>当前位置：
					<router-link to="/home"> 首页</router-link>
					 >> 
					<router-link to="/recruit">校园招聘会</router-link>
				  </h1>
				</div>
			</div>
			
			
			
			<div class="center" style="border: 1px solid #CCCCCC;height: 400px;margin-top: 40px;">
				<h2 style="text-align: center;">{{recruit.recruitTitle}}</h2>
				<div style="display: flex;margin-top: 80px;margin-left: 100px;">
					<img :src="baseUrl+recruit.picPath" style="width: 300px;height: 200px;">
					<div style="font-size: 18px;line-height: 45px;margin-left: 100px;margin-top: 20px;">
						<div style="display: flex;">
							<div style="font-weight: 700;margin-right: 15px;">主办单位:</div> {{recruit.depart}}
						</div>
						<div style="display: flex;">
							<div style="font-weight: 700;">开始时间：</div>{{recruit.startTime | dateFormat}}
						</div>
						<div style="display: flex;">
							<div style="font-weight: 700;">结束时间：</div>{{recruit.endTime | dateFormat}}
						</div>
					</div>
					</div>
			</div>
			
			
			
			
			
			<div style="border: 1px solid #CCCCCC;width: 200px;height: 50px;margin-top: 30px;background-color: #409EFF;">
				<h2 class="invite" style="text-align: center;line-height: 10px;">邀请函</h2>
			</div>
			<div style="border: 1px solid #CCCCCC;width: 1186px;">
			<div class="recruit_content" v-html="recruit.recruitContent" style="width: 1100px;margin: 0 auto;margin-top: 40px;margin-bottom: 40px;"></div>
			</div>
			<div style="float: right;font-size: 24px; margin-top: 30px;">主办方：{{recruit.depart}}
				<div v-if="recruit.filePath!=null&recruit.filePath!=''" style="font-size: 16px;margin-top: 10px;text-align: center;color: red;" @click="lookFile(recruit.filePath)">文件查看</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import {getRecruitDetail} from '../../api/index';
export default{
	data(){
		return{
			baseUrl:"http://39.108.229.129:82",
			rid:'',
			recruit:{},
		}
	},
	created(){
		this.rid = this.$route.query.id
		this.recruitDetail()
	},
	methods:{
		recruitDetail(){
			getRecruitDetail(this.rid).then(res=>{
				console.log(res)
				this.recruit = res.data
			})
		},
		//查看文件
		lookFile(file){
			window.open(file)
		}
	}
}
</script>

<style scoped>
.wrapper {
    MARGIN: 0px auto;
    WIDTH: 1188px;
    height: auto;
    background-color: #FFFFFF;
    overflow: hidden;
}
.cnt {

    width: 1188px;
    height: auto;
    background-color: #ffffff;
    margin: 50px 0 0 0;
    overflow: hidden;
}
.cnt_top {
    border-bottom: #CCCCCC 1px solid;
    width: 1188px;
    height: 49px;
    overflow: hidden;
}
.top_title {
    color: #010204;
    font-size: 22px;
    float: left;
    width: 50%;
    height: 50px;
    overflow: hidden;
}
.top_title h1 {
    color: #373333;
    float: left;
    width: 100%;
    line-height: 20px;
    text-align: left;
    text-indent: 5px;
    font-size: 22px;
    font-weight: 800;
}
.top_path {
    display: block;
    color: #968E8E;
    font-size: 12px;
    height: 50px;
    line-height: 66px;
    width: auto;
    padding-right: 5px;
    overflow: hidden;
}
.top_path h1 {
    color: #968E8E;
    height: 50px;
    line-height: 20px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
}
.top_path h1 a:visited {
    color: #968E8E;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
}

	
/* 校园招聘会 */
/* .invite{
	margin-top: 60px;
	text-align: center;
} */
.recruit_content{
	font-size: 24px;
	line-height: 30px;
}

</style>
