<template>
	<div id="building">
		<div class="login-box" >
			<h1 style="text-align: center;margin-bottom: 50px;">欢迎登录</h1>
			<el-form  ref="form" label-width="80px" >	
				<el-form-item label="账号">
				   <el-input placeholder="请输入账号" v-model="form.username" type="text"></el-input>
				</el-form-item>
				<el-form-item label="密码">
				   <el-input placeholder="请输入密码" v-model="form.password"  show-password></el-input>
				</el-form-item>
				<el-form-item label="用户类型">
				    <el-radio-group v-model="form.types">
				      <el-radio label="企业用户"></el-radio>
				      <el-radio label="院校用户"></el-radio>
				    </el-radio-group>
				  </el-form-item>
				<el-form-item >
				   <el-button @click="onSubmit" type="primary" style="width: 250px;">登录</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import {login} from '../../api/index'
	export default{
		data(){
			return{
				form:{
					'username':'',
					'password':'',
					'types':''
				}
			}
		},
		methods:{
			onSubmit(){
				console.log(this.form)
				if(this.form.types=="企业用户"){
					this.form.types=2
				}
				if(this.form.types=="院校用户"){
					this.form.types=1
				}
				let params={...this.form}
				if(this.form.username==''&&this.form.password==''){
					alert("请输入用户名或密码")
					return
				}
				if(this.form.types==''){
					alert("请勾选用户类型")
					return
				}
				login(params).then(res=>{
					this.form.types=''
					console.log(res)
					if(res.code==1){
						sessionStorage.setItem("token",res.data.token);
						sessionStorage.setItem("id",res.data.id);
						sessionStorage.setItem("userType",res.data.userType);
						this.$router.push("/home")
					}else{
						alert("用户名或密码错误")
					}
					
				})
				
			}
		}
	}
</script>

<style scoped lang="less">
#building{
  background:url("../../assets/loginbg.jpg");
  width:100%;
  height:100%;
  position:fixed;
  background-size:100% 100%;
}
.login-box{
  border:1px solid #dccfcf;
  width: 350px;
  margin:180px auto;
  padding: 35px 80px 15px 35px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 0 0 25px #909399;
  background-color:rgba(255,255,255,0.7);
}
.el-input{
	width: 300px;
}

</style>
